.indexHeader {

  .content {
    display: flex;
    flex-direction: column;

    padding: 100px 50px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0) 100%);

    @media only screen and (max-width: 600px) {
      /* SMARTPHONE */
      padding: 200px 20px 50px 20px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0) 90%);
    }

    @media only screen and (min-width: 760px) {
      padding: 200px 150px;
      max-width: 500px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0) 100%);
    }

    .title {
      font-size: 50px;
      font-family: 'Bowlby One SC', cursive;
    }

    .description {
      font-size: 15px;
      color: rgb(230, 230, 230);
      margin: 30px 0px;
      font-family: 'Assistant', sans-serif;
    }

    .domainText {
      @media only screen and (max-width: 600px) {
        text-align: center;
      }
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.indexLinkButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
}


.indexJoinVoice {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    bottom: -160px;
    height: auto;
  }

  .voiceContainer {
    transition: .5s;
    cursor: Pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: rgba(13, 42, 53, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    width: 300px;

    @media only screen and (max-width: 600px) {
      width: auto;
      margin: 10px 20px;
      padding: 20px 0px;
    }

    span {
      font-size: 30px;
      font-weight: bold;
    }

    img {
      transition: .5s;
      height: 30px;
    }

    &:hover {
      transition: .5s;
      background: rgba(13, 42, 53, 1);
      box-shadow: 0px 0px 30px 3px rgb(150, 0, 0);

      .voiceJoinArrow {
        transform: translate(5px, 0px);
      }
    }
  }
}

.tutorialContainer {
  padding: 20px;

  @media only screen and (max-width: 600px) {
    padding-top: 100px;
  }

  .indexTutorialDescriptionContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    span {
      @media only screen and (max-width: 600px) {
        text-align: center;
        margin-bottom: 20px;
      }
      text-transform: uppercase;
      font-size: 25px;
      height: 30px;
    }

    .indexTutorialDescription {
      z-index: 2;
      border-radius: 10px;
      padding: 10px;
      background: rgba(0, 55, 80, 0.8);
      @media only screen and (min-width: 600px) {
        margin-right: 20px;
      }

      @media only screen and (max-width: 600px) {
        backdrop-filter: blur(2px);
        background: rgba(25, 25, 25, 0.6);
      }

      ol {
        list-style: none;
        counter-reset: tutdesc-inc;

        li {
          counter-increment: tutdesc-inc;
          margin: 0 0 1rem 0;
          padding-top: 4px;
          position: relative;
          font-weight: bold;

          &::before {
            content: counter(tutdesc-inc);
            color: white;
            font-size: 1.5rem;
            font-weight: bold;
            position: absolute;
            --size: 30px;
            left: calc(-1 * var(--size) - 10px);
            line-height: var(--size);
            width: var(--size);
            height: var(--size);
            top: 0;
            background: #0f6a94;
            border-radius: 50%;
            text-align: center;
          }
        }
      }
    }
  }

  .indexTutorialVideo {
    border: solid 5px rgb(74, 137, 162);
    border-radius: 10px;
    background: rgba(0, 55, 80, 0.3);
    z-index: 1;

    @media only screen and (max-width: 600px) {
      min-height: 220px
    }
  }
}