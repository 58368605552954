@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300&display=swap');

body {
  background: black;
  color: white;
  height: 100%;
  font-family: 'Assistant', sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(150, 150, 150);
}

::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiDrawer-root .MuiDrawer-paper {
  background: rgb(20, 20, 20);
}

.navDrawer {

  ul {
    list-style-type: none;
    display: flex;
    padding: 0px;
    flex-direction: column;

    li {
      margin: 10px 0px;


      a, a:visited, a:active {
        transition: .2s;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        padding: 5px 70px;
      }

      a:hover {
        transition: .2s;
        color: rgb(200, 200, 200)
      }
    }
  }
}

.headerContainer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
  transition: 1s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999999;


  nav {

    list-style-type: none;
    display: flex;
    justify-content: space-evenly;

    padding: 30px 0px;

    color: white;
    font-weight: bold;
    text-transform: uppercase;

    font-size: 20px;


    img {
      height: 70px
    }

    .header {
      display: inline-block;

      ul {
        list-style-type: none;
        display: flex;
        justify-content: center;
        padding: 0px;

        li {
          margin: 0px 20px;

          a, a:visited, a:active {
            transition: .2s;
            text-decoration: none;
            color: white;
          }

          a:hover {
            transition: .2s;
            color: rgb(200, 200, 200)
          }
        }
      }
    }

    .buttonsDiv {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}