.unlockRoot {

  height: 100vh;

  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 70%);

  @media only screen and (min-width: 600px) {
    padding-left: 50px;
  }

  @media only screen and (max-width: 600px) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0) 80%);
    justify-content: center;
  }

  .unlockContainer {
    display: flex;
    flex-direction: column;


    .title {
      font-weight: bold;
      margin-bottom: 30px;

      div {
        font-size: 60px;
        @media only screen and (max-width: 600px) {
          font-size: 40px;
        }
      }
    }


    .form {
      display: flex;
      flex-direction: column;

      span {
        color: #bababa;
      }

      .subtitle {
        font-size: 20px;
        color: white;
      }

      input {
        border: solid 2px grey;
        background: rgba(0, 0, 0, 0.8);
        font-size: 25px;
        padding: 5px;
        color: white;
        margin: 5px 0px;
        border-radius: 5px;
      }

      input[type=button] {
        transition: .5s;
        background: #c2008d;
        border: none;
        padding: 10px 5px;
        cursor: Pointer;

        &:hover {
          background: #ff19c1;
        }
      }
    }
  }

}